import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {setTheme} from "../../redux/appReducer";
import Theme from "./Theme";


const ThemeContainer: React.FC = (props) => (


    <Theme {...props} />
);


let mapStateToProps:any = (state:any) => {
    return {
        // auth: state.auth,
        app: state.app
    }
};

export default compose(connect(mapStateToProps,{setTheme}))(ThemeContainer);