import React from 'react';

const SignUp = () => {
    return (
        <div>
            <h1>SignUp Page</h1>
            <p>SignUp will be here soon...</p>
        </div>
    );
};

export default SignUp;
