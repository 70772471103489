import React from 'react';
import {Card, Col, Row, Statistic} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";


let a: string = '';
a = '34';

let fruits: Array<string | null | number> = ['apple','orange',43,null];

const Home = () => {

    const { t, i18n} = useTranslation('',{ keyPrefix: 'home' });
    return (
        <div>
            {/*<h1>Home Page</h1>*/}
            {/*<p>Welcome to the home page!</p>*/}
            <h1>{t('Home page')}</h1>
            <p>{t('descr')}</p>
            <Row gutter={16}>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t("Earned")}
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t("CBs")}
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t("Calls")}
                            value={19.3}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t("Conversion Rate")}
                            value={49.7}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Home;
