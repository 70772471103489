//
// let initialState = {}
//
// const countriesReducer = (state = initialState, action:any) => {
//     switch (action.type) {
//
//         default:
//             return state;
//     }
// }
//
// export default countriesReducer;
//

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import {API} from "../api/API";

const SET_NEW_COUNTRY_ADDED_STATUS = 'crm/countries/SET_NEW_COUNTRY_ADDED_STATUS';
const SET_NEW_COUNTRY_ADDED_ERROR = 'crm/countries/SET_NEW_COUNTRY_ADDED_ERROR';
const SET_NEW_COUNTRY_ADDED_ID = 'crm/countries/SET_NEW_COUNTRY_ADDED_ID';
const SET_LOADING = 'crm/countries/SET_LOADING';
const SET_COUNTRIES = 'crm/countries/SET_COUNTRIES';

type Country = {
    id: number | null,
    country: string | null,
    active: number | null,
    comment: string | null,
}
type SelectedCountry = {
    id: number | null,
    country: string | null,
    active: boolean | null,
    comment: string | null,
}

let initialState = {
    countries: []  as Array<Country>,
    selectedCountry: {
        id: null,
        country: null,
        active: null,
        comment: null,
    } as SelectedCountry,
    country: null as string | null,
    active: true as boolean | null,
    comment: null as string | null,
    newCountryAddedStatus: false,
    newCountryAddedError: false,
    newCountryAddedErrorText: '' as string,
    newCountryAddedId: null as null | number,
    loading: false as boolean | null

}

const countriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // case SET_NEW_CLIENT_ADDED_STATUS:
        //     return {
        //         ...state,
        //         newClientAddedStatus: action.status
        //     };
        // case SET_NEW_CLIENT_ADDED_ERROR:
        //     return {
        //         ...state,
        //         newClientAddedError: action.error,
        //         newClientAddedErrorText: action.errorText
        //     };
        // case SET_NEW_CLIENT_ADDED_ID:
        //     return {
        //         ...state,
        //         newClientAddedId: action.newClientAddedId
        //     };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.countries,
            };
        default:
            return state;
    }
}

const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading,
    }
)

const setCountriesAC = (countries: any) => (
    {
        type: SET_COUNTRIES,
        countries: countries,
    }
)

// const setNewClientAddedStatusAC = (status: boolean) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_STATUS,
//         status: status
//     }
// )
//
// const setNewClientAddedIdAC = (newClientAddedId: number) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_ID,
//         newClientAddedId: newClientAddedId
//     }
// )
//
// const setNewClientAddedErrorAC = (error: boolean, errorText: string) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_ERROR,
//         error: error,
//         errorText: errorText
//     }
// )

// thunks

export const getCountries = () => async (dispatch: any) => {

    await API.getCountries()
        .then((data)=>{
            if(data.result === 'ok'){
                dispatch(setCountriesAC(JSON.parse(data.countries)));
            }else{
                console.log(data);
            }
            dispatch(setLoading(false));

        })
        .catch((error)=>{
            console.error('error: ' + error)
            dispatch(setLoading(false));
        });
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setLoadingAC(loading))
}
/*


export const setNewClientData = (email: string | undefined,password: string | undefined,fullName: string | undefined,isActive: boolean | undefined,isOnline: boolean | undefined,lastLogInDate: number | undefined,lastActionDate: number | undefined,lastAction: string | undefined,country: string | undefined,type: string | undefined,country: string | undefined,createDate: number | undefined,createdBy: string | undefined,assignedToID: number | undefined,phone: string | undefined,address: string | undefined,status: string | undefined,lastCommentDate: number | undefined,country: string | undefined,campaignID: number | undefined) => async (dispatch: any) => {
    // dispatch(setAuthDataAC(login, pass, rememberMe))
    await API.addClient(email,password,fullName,isActive,isOnline,lastLogInDate,lastActionDate,lastAction,country,type,country,createDate,createdBy,assignedToID,phone,address,status,lastCommentDate,country,campaignID)
        .then((data)=>{
            if(data.result === 'ok' && data.clientAdded === true){
                dispatch(setNewClientAddedStatusAC(true));
                dispatch(setNewClientAddedIdAC(data.userId));
                dispatch(setNewClientAddedErrorAC(false,''));
                dispatch(setLoadingAC(false));
            }else{
                dispatch(setNewClientAddedErrorAC(true,data.error));
                dispatch(setNewClientAddedStatusAC(false));
                dispatch(setLoadingAC(false));
                // debugger
            }
            console.log(data);
            // dispatch(setAuthStatusAC(data.status === 'logged in' ));
        })
        .catch((error)=>{
            console.error('error: ' + error)
        });

    // API - auth
    // if ok - set auth data null and  authStatus = true
    // if not ok - show error and  authStatus = true
}

export const setNewClientAddedStatus = (status: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setNewClientAddedStatusAC(status))
}
*/


//

export default countriesReducer;