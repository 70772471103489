import React from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import SideMenuContainer from "../SideMenu/SideMenuContainer";
import {Link, Route, Routes} from "react-router-dom";
import Home from "../Home/Home";
import LoginContainer from "../LoginContainer/LoginContainer";
import SignUp from "../SignUp/SignUp";
import Clients from "../Clients/Clients";
import UsersContainer from "../Users/UsersContainer";
import AddUserContainer from "../AddUser/AddUserContainer";
import logo from '../../imgs/myCRM_logo.png'
import css from './Main.module.css'
import {compose} from "redux";
import {connect} from "react-redux";
import {setAuthData, setLoading} from "../../redux/authReducer";
import Login from "../LoginContainer/Login/Login";
import Main from "./Main";

const MainContainer: React.FC = (props) => (

    <Main {...props} />
);


let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
    }
};

export default compose(connect(mapStateToProps,{}))(MainContainer);