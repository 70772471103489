// import React from 'react';
// import {Skeleton} from "antd";
//
// const Users = () => {
//     return (
//         <div>
//             {/*<h1>Users Page</h1>*/}
//             {/*<p>Users will be here soon...</p>*/}
//             <h1>Users Page</h1>
//             <p>(список пользователей CRM будет доступен только тимам и админам)</p>
//             <Skeleton />
//         </div>
//     );
// };

import React, {useCallback, useEffect, useRef, useState} from 'react';
import type { GetProp, TableProps } from 'antd';
import {Input, Select, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import css from "../Clients/Clients.module.css";
import { createStyles } from 'antd-style';
import {getClients} from "../../redux/clientsReducer";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    fullName: string;
    // lastName: string;

    // gender: string;
    email: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    clientid: number,
    createDate: number,
    desk: string,
    phone: string,
    password: string,
    isActive: string,
    isOnline: string,
    lastLogInDate: number,
    lastActionDate: number,
    lastAction: string,
    type: string,
    brand: string,
    assignedToID: string,
    status: string,
    country: string,
    campaignID: string,
    utm_campaign: string,
    ip: string,
    comment: string,
    additional_info: string,
    lastCommentDate: number,
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Clients: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'clients' });

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    // debugger

    // one time load data from server
    useEffect(()=>{
        props.setLoading(true);
        props.getClients([],100);
    },[])

    // if store updates
    useEffect(()=>{
        setDataSource(props.clients.clients);
    },[props.clients.clients])

    // main dataSource
    const [dataSource, setDataSource] = useState(props.clients.clients);

    //////////////////////////////////////////////   for filtering ----- start  //////////////////////////////////////////////

    const [arrParams, setArrParams] = useState({});

    const [valueName, setValueName] = useState('');
    const [valuePhone, setValuePhone] = useState('');
    const [valueEmail, setValueEmail] = useState('');

    const [showSearchBarName,setSSSName] = useState(false);
    const [showSearchBarPhone,setSSSPhone] = useState(false);
    const [showSearchBarEmail,setSSSEmail] = useState(false);

    let showSearchName = () => {
        setSSSName(true);
    }

    let showSearchPhone = () => {
        setSSSPhone(true);
    }
    let showSearchEmail = () => {
        setSSSEmail(true);
    }



    const FilterByNameInput = (
        <>
            <Input
                style={!showSearchBarName ? { display: 'none' } : {}}
                placeholder="Search Name"
                value={valueName}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['fullName'] = currValue
                    setArrParams(tempArr)
                    setValueName(currValue);
                    timer(timerHandler);
                }}
            />
            <div
                style={showSearchBarName ? { display: 'none' } : {}}
                className={css.title}>Name <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchName()}/></div>
        </>

    );

    const FilterByPhoneInput = (
        <>
            <Input
                style={!showSearchBarPhone ? { display: 'none' } : {}}
                placeholder="Search Phone"
                value={valuePhone}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['phone'] = currValue
                    setArrParams(tempArr)
                    setValuePhone(currValue);
                    timer(timerHandler);
                }}
            />
            <div
                style={showSearchBarPhone ? { display: 'none' } : {}}
                className={css.title}>Phone <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchPhone()}/></div>
        </>

    );

    const FilterByEmailInput = (
        <>
            <Input
                style={!showSearchBarEmail ? { display: 'none' } : {}}
                placeholder="Search Email"
                value={valueEmail}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['email'] = currValue
                    setArrParams(tempArr)
                    setValueEmail(currValue);
                    timer(timerHandler);
                }}
            />
            <div
                style={showSearchBarEmail ? { display: 'none' } : {}}
                className={css.title}>Email <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchEmail()}/></div>
        </>

    );


    // timer
    const timerHandler = useRef(null);
    let timer = (timerHandler:any) => {
        clearTimeout(timerHandler.currTimer);
        timerHandler.currTimer = setTimeout(()=>{
            props.getClients(arrParams);
        },2000);
    }

    // ESC
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.key === 'Escape') {
                setSSSName(false);
                setValueName('');
                setSSSPhone(false);
                setValuePhone('');
                setSSSEmail(false);
                setValueEmail('');
                setArrParams({});
                // setDataSource(props.clients.clients);
                props.getClients();
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    //////////////////////////////////////////////   for filtering ----- end  //////////////////////////////////////////////

    // debugger;
    const columns: ColumnsType<DataType> = [
        {
            title: FilterByNameInput,
            dataIndex: 'fullName',
            // sorter: (a,b) => (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName),
            // render: (text, record) => (
            //     `${record.firstName} ${record.lastName}`
            // ),
            // width: '20%',
            width: 'auto',
            fixed: 'left',
        },
        {
            title: FilterByPhoneInput,
            dataIndex: 'phone',
            // filters: [
            //     { text: 'Poland', value: 'poland' },
            //     { text: 'Spain', value: 'spain' },
            // ],

            sorter: {
                compare: (a,b) => (a.phone).localeCompare(b.phone),
                multiple: 2,
            },
            width: 'auto',
            fixed: 'left',
        },

        {
            title: FilterByEmailInput,
            dataIndex: 'email',
            sorter: (a,b) => (a.email).localeCompare(b.email),
            width: 'auto',
            fixed: 'left',
        },

        {
            title: 'Desk',
            dataIndex: 'desk',
            filters: [
                { text: 'Poland', value: 'poland' },
                { text: 'Spain', value: 'spain' },
            ],
            sorter: {
                compare: (a,b) => (a.desk).localeCompare(b.desk),
                multiple: 2,
            },
            showSorterTooltip: {
                target: 'sorter-icon'
            },
            width: 'auto',
            render: (_,d) => {
                return props.desks.desks.map((desk:any)=>{
                    return desk.id === d.desk ? desk.desk : null;
                })
            }
        },
        {
            title: 'Create date',
            dataIndex: 'createDate',
            filters: [
            ],
            // defaultSortOrder: 'descend',

            sorter: {
                compare: (a, b) => a.createDate - b.createDate,
                multiple: 2,
            },
            // sorter: true,
            width: 'auto',
            render: (_,d) => Intl.DateTimeFormat('ru',{timeStyle:'medium',dateStyle:'short'}).format(d.createDate*1000),
        },
        // {
        //     title: 'Age',
        //     dataIndex: 'dob',
        //     filters: [
        //     ],
        //     sorter: true,
        //     width: '20%',
        //     render: (a) => a.age,
        // },

        { title: 'password', dataIndex: 'password', key: 'password',
            width: 'auto',
            render: (_,d) => d.password,
        },
        { title: 'active', dataIndex: 'isActive', key: 'isActive',
            width: '150px',
            render: (_,d) => d.isActive === '1' ? 'yes' : 'no', },
        { title: 'online', dataIndex: 'isOnline', key: 'isOnline',
            width: '150px',
            render: (_,d) => d.isOnline === '1' ? 'yes' : 'no', },
        { title: 'last LogIn Date', dataIndex: 'lastLogInDate', key: 'lastLogInDate', width: '150px',
            render: (_,d) => d.lastLogInDate != 0 ? (new Date(d.lastLogInDate*1000).toUTCString()) : ''  },
        { title: 'last Action Date', dataIndex: 'lastActionDate', key: 'lastActionDate', width: '150px',
            render: (_,d) => d.lastActionDate != 0 ? Intl.DateTimeFormat('ru',{timeStyle:'medium',dateStyle:'short'}).format(d.lastActionDate*1000) : ''
        },
        { title: 'last Action', dataIndex: 'lastAction', key: 'lastAction', width: '150px' },
        { title: 'type', dataIndex: 'type', key: 'type', width: '150px' },
        { title: 'brand', dataIndex: 'brand', key: 'brand', width: '150px',
            render: (_,d) => {
                return props.brands.brands.map((brand:any)=>{
                    return brand.id === d.brand ? brand.brand : null;
                })
            }
        },
        { title: 'assigned To', dataIndex: 'assignedToID', key: 'assignedToID', width: '150px',
            render: (_,d) => {
                return props.users.users.map((user:any)=>{
                    return user.userid === d.assignedToID ? user.fullName : null;
                })
            }
        },
        { title: 'status', dataIndex: 'status', key: 'status', width: '150px' },
        { title: 'country', dataIndex: 'country', key: 'country', width: '150px' },
        { title: 'campaign', dataIndex: 'campaignID', key: 'campaignID', width: '150px',
            render: (_,d) => {
                return props.campaigns.campaigns.map((campaign:any)=>{
                    return campaign.id === d.campaignID ? campaign.campaign : null;
                })
            }},
        { title: 'utm campaign', dataIndex: 'utmCampaign', key: 'utmCampaign', width: '150px' },
        { title: 'ip', dataIndex: 'ip', key: 'ip', width: '150px' },
        { title: 'comment', dataIndex: 'comment', key: 'comment', width: '150px' },
        { title: 'additional info', dataIndex: 'additionalInfo', key: 'additionalInfo', width: '150px' },
        { title: 'last Comment Date', dataIndex: 'lastCommentDate', key: 'lastCommentDate', width: '150px',
            render: (_,d) => d.lastCommentDate != 0 ? (new Date(d.lastCommentDate*1000).toUTCString()) : '' },

        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: () => <a>action</a>,
        },
    ];
    const fetchData = () => {
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    // const { styles } = useStyle();

    return (
        <>
            <h1>{t("Clients List")}</h1>
            <Link to="/addClient">{t('addClient')}</Link>
            <Table
                className={css.table}

                columns={columns}
                rowKey={(record) => record.clientid}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.clients.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}

                scroll={{ x: 'max-content', y: 105 * 5 }}
            />
        </>

    );
};

export default Clients;
