import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import "./common/locales/i18n"

// import AppContainer from "./AppContainer";

// Создаем корневой узел один раз
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

let rerenderEntireTree = (state: any) => {
    root.render(
        // <React.StrictMode>
            <App state={state} />
        // </React.StrictMode>
    );
}

// Начальная отрисовка
rerenderEntireTree(store.getState());

// Подписка на изменения в состоянии
store.subscribe(() => {
    let state = store.getState();
    rerenderEntireTree(state);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
