import React from 'react';
import {compose} from "redux";
import { connect } from 'react-redux';
import SideMenu from "./SideMenu";
import { exit } from '../../redux/authReducer';
import {setActiveTab} from "../../redux/appReducer";



const SideMenuContainer: React.FC = (props) => (


    <SideMenu {...props} />
);


let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        app: state.app
    }
};

export default compose(connect(mapStateToProps,{exit,setActiveTab}))(SideMenuContainer);
