import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {setLoading} from "../../redux/clientsReducer";
import Clients from './Clients';
import { getClients } from '../../redux/clientsReducer';

const ClientsContainer : React.FC = (props) => {
    return (
        <Clients {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {

    // debugger;
    return {
        auth: state.auth,
        clients: state.clients,
        brands: state.brands,
        desks: state.desks,
        campaigns: state.campaigns,
        users: state.users,
    }
};

export default compose(connect(mapStateToProps,{getClients,setLoading}))(ClientsContainer);
