import { configureStore} from "@reduxjs/toolkit";
import {combineReducers, createStore} from "redux";
import authReducer from "./authReducer"
import thunkMiddleware from "redux-thunk"
// import contactsReducer from "./contactsReducer";
import usersReducer from "./usersReducer";
import appReducer from "./appReducer";
import clientsReducer from "./clientsReducer";
import desksReducer from "./desksReducer";
import brandsReducer from "./brandsReducer";
import statusesReducer from "./statusesReducer";
import countriesReducer from "./countriesReducer";
import campaignsReducer from "./campaignsReducer";

let store = configureStore(
    {reducer: {
        auth: authReducer,
        users: usersReducer,
        desks: desksReducer,
        brands: brandsReducer,
        statuses: statusesReducer,
        campaigns: campaignsReducer,
        countries: countriesReducer,
        app: appReducer,
        clients: clientsReducer,
    },
    // middleware: {
    //     thunkMiddleware
    // }
    });

export default store;