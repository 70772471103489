import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, FormProps, Input, Result, Select, Space, TreeSelect, Typography} from "antd";
import {Link} from "react-router-dom";
import css from './AddClient.module.css'
import { EN } from '../../common/locales/en';
import {RU} from "../../common/locales/ru";
// import {translate} from "../../common/translate";
import { useTranslation } from 'react-i18next';
import ShowError from "../ShowError/ShowError";
// import {setNewClientAddedStatus, setNewClientAddedStatusAC} from "../../redux/clientsReducer";



type FieldType = {
    fullName?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    password?: string | undefined;
    isActive?: boolean | undefined;
    desk?: string | undefined;
    type?: string | undefined;
    brand?: string | undefined;
    createDate?: number | undefined;
    createdBy?: string | undefined;
    address?: string | undefined;
    status?: string | undefined;
    country?: string | undefined;
    assignedToID?: number | undefined;
    campaignID?: number | undefined;
};


const AddClient: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'addClient' });

    const [form] = Form.useForm();


    const onReset = () => {
        form.resetFields();
    };

    // let [loading, setLoading] = useState(false);
    // let [doTranslate, setDoTranslate] = useState(false);
    useEffect(() => {
        if (props.clients.newClientAddedStatus) {
            props.setNewClientAddedStatus(false);
            // setShowSuccess(true);
            // setShowSuccessCounter(showSuccessCounter++);
            // console.log('showSuccessCounter - ' + showSuccessCounter)
        } else {
            // setShowSuccess(false)
        }
    }, [])

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);

        props.setNewClientData(values.email,values.password,values.fullName,values.isActive,false,0,0,'',values.desk,values.type,values.brand,values.createDate,values.createdBy,values.assignedToID,values.phone,values.address,values.status,0,values.country,values.campaignID);
        props.setLoading(true);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onClose = () => {
        props.setLoading(false);
        props.setNewClientAddedStatus(false);
        onReset();
    }


    const tailLayout = {
        // wrapperCol: {offset: 8, span: 16},
    };

    // const doTranslate: any = (key:string,value:string) => {
    //     return translate(key,value);
    // }

    // useEffect(()=>{
    //     if(props.app.language === 'ru'){
    //         doTranslate
    //     }
    // },[props.app.language])


    // debugger
    return (props.clients.newClientAddedStatus ?
            <Result
                status="success"
                title={t('clients','Client Added Successfully!')}
                subTitle={t('We advice you to setup this client\'s permissions')}
                extra={[
                    <Button type="primary" key="permissions" disabled>
                        {t('Set Permissions')}
                    </Button>,
                    <Button key="goToList">
                        <Link to="/clients">{t('Go to clients list')}</Link>
                    </Button>,
                    <Button key="close" onClick={onClose}>{t('Close/Add One More Client') }</Button>,
                ]}
            />
            :
            <div>
                <Typography.Title className={'center'}>{t('Add new client')}</Typography.Title>
                <Form className={`m_auto left`}

                      form={form}
                      name="basic"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                      style={{maxWidth: 400}}
                      initialValues={{
                          email: 'email@mail.com',
                          password: 'myPassword123',
                          fullName: 'Michael Andrews',
                          isActive: true,
                          desk: 'Poland desk',
                          role: 'Manager',
                          brand: '42-Fin',
                          supervisorID: 3
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="vertical"
                >
                    <ShowError errors={[props.clients.newClientAddedErrorText !== '' ? t(props.clients.newClientAddedErrorText) : '']} />

                    <Form.Item<FieldType> label={t('Full Name')} name="fullName" rules={[{
                        required: true,
                    }]}><Input/></Form.Item>
                    <Form.Item<FieldType> label={t('Phone')} name="phone" rules={[{
                        required: true,
                        // message: 'Please input email!'
                    }]}><Input/></Form.Item>
                    <Form.Item<FieldType> label={t('Email')} name="email" rules={[{
                        required: true,
                        // message: 'Please input email!'
                    }]}><Input/></Form.Item>
                    <Form.Item<FieldType> label={t('Password')} name="password" rules={[{
                      required: true,
                      // message: 'Please input password!'
                    }]}>
                    <Input count={{show: true}}/></Form.Item>
                    <Form.Item<FieldType> label={t('Address')} name="address" rules={[{
                        required: true,
                        // message: 'Please input email!'
                    }]}><Input/></Form.Item>
                    {/*<Form.Item<FieldType> label={t('Last Name')} name="lastName" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    // message: 'Please input lastName!'*/}
                    {/*}]}><Input/></Form.Item>*/}
                    <Form.Item<FieldType> label="" name="isActive"
                                          valuePropName="checked"><Checkbox>{t('Active')}</Checkbox></Form.Item>

                    <Form.Item label={t('Desk')} name="desk" rules={[{
                        required: true,
                        // message: 'Please input desk!'
                    }]}>
                        <Select>
                            {props.desks.desks.map((desk:any)=>{
                                return desk.active === "1" ? <Select.Option key={desk.id} value={desk.desk}>{desk.desk}</Select.Option> : null;
                            })}
                            {/*<Select.Option value="Poland">Poland Desk</Select.Option>*/}
                            {/*<Select.Option value="Spain">Spain Desk</Select.Option>*/}
                            {/*<Select.Option value="German">German Desk</Select.Option>*/}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('Type')} name="type" rules={[{
                        required: true,
                    }]}>
                        <Select>
                            <Select.Option value="lead">Lead</Select.Option>
                            <Select.Option value="client">Client</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('Status')} name="status" rules={[{
                        required: true,
                    }]}>
                        <Select>
                            {props.statuses.statuses.map((status:any)=>{
                                return status.active === "1" ? <Select.Option key={status.id} value={status.status} style={{ backgroundColor: status.bgColor, color: status.color}}>{status.status}</Select.Option> : null;
                            })}
                            {/*<Select.Option value="hot">hot</Select.Option>*/}
                            {/*<Select.Option value="new">new</Select.Option>*/}
                            {/*<Select.Option value="no potential">no potential</Select.Option>*/}
                            {/*<Select.Option value="no answer">no answer</Select.Option>*/}
                        </Select>
                    </Form.Item>
{/*
                    <Form.Item label={t('Role')} name="role" rules={[{
                        required: true,
                        // message: 'Please input role!'
                    }]}>
                        <Select>
                            <Select.Option value="SuperAdmin">SuperAdmin</Select.Option>
                            <Select.Option value="Admin">Admin</Select.Option>
                            <Select.Option value="Supervisor">Supervisor</Select.Option>
                            <Select.Option value="Manager">Manager</Select.Option>
                            <Select.Option value="Agent">Agent</Select.Option>
                            <Select.Option value="Guest">Guest</Select.Option>
                        </Select>
                    </Form.Item>*/}
                    <Form.Item label={t('Campaign')} name="campaignID" rules={[{
                        required: true,
                    }]}>
                        <Select>
                            {props.campaigns.campaigns.map((campaign:any)=>{
                                return campaign.active === "1" ? <Select.Option key={campaign.id} value={campaign.campaign} >{campaign.campaign}</Select.Option> : null;
                            })}

                            {/*<Select.Option value="1">Poland 1</Select.Option>*/}
                            {/*<Select.Option value="2">Germany FB</Select.Option>*/}
                            {/*<Select.Option value="3">ProfitHunters PL</Select.Option>*/}
                            {/*<Select.Option value="4">TRD 22</Select.Option>*/}
                            {/*<Select.Option value="5">Test</Select.Option>*/}
                            {/*<Select.Option value="6">Poland hot manual</Select.Option>*/}
                        </Select>
                    </Form.Item>

                    <Form.Item label={t('Brand')} name="brand" rules={[{
                        required: true,
                        // message: 'Please input brand!'
                    }]}>
                        <Select>
                            {props.brands.brands.map((brand:any)=>{
                                return brand.active === "1" ? <Select.Option key={brand.id} value={brand.brand} >{brand.brand}</Select.Option> : null;
                            })}

                            {/*<Select.Option value="trd">TRD</Select.Option>*/}
                            {/*<Select.Option value="42">42</Select.Option>*/}
                            {/*<Select.Option value="bern">Bern</Select.Option>*/}
                        </Select>


                    </Form.Item>
                    <Form.Item label={t('Country')} name="country" rules={[{
                        required: true,
                    }]}>
                        <Select>
                            {props.countries.countries.map((country:any)=>{
                                return country.active === "1" ? <Select.Option key={country.id} value={country.country} >{country.country}</Select.Option> : null;
                            })}


                            {/*<Select.Option value="Germany">Germany</Select.Option>*/}
                            {/*<Select.Option value="United Kingdom">United Kingdom</Select.Option>*/}
                            {/*<Select.Option value="Poland">Poland</Select.Option>*/}
                        </Select>


                    </Form.Item>

                    <Form.Item label={t('Assigned To')} name="assignedToID"
                               rules={[{
                                   required: true,
                                   // message: 'Please input supervisorID!'
                               }]}>
                        <TreeSelect
                            treeData={[
                                {title: 'none', value: '0', children: []},
                                {title: 'SuperAdmin', value: '1', children: [{title: 'Admin', value: '2'}]},
                                {title: 'Roman', value: '8', children: [{title: 'Tom', value: '9'}]},
                                {
                                    title: 'Anita',
                                    value: '5',
                                    children: [{title: 'Bill', value: '6'}, {title: 'Gabby', value: '7'},]
                                },
                                {title: 'Test', value: '17', children: []},
                                {title: 'Daniel', value: '3', children: [{title: 'Alex', value: '4'}]},
                            ]}
                        />
                    </Form.Item>

                    <Form.Item  {...tailLayout}
                        // wrapperCol={{ offset: 8, span: 16 }}
                    >

                        <Space>
                            <Button type="primary" htmlType="submit" loading={props.clients.loading} disabled={props.clients.loading}>
                                {t('Add Client')}
                            </Button>

                            <Button htmlType="button" onClick={onReset} loading={props.clients.loading} disabled={props.clients.loading}>
                                {t('Reset')}
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
    );
};

export default AddClient;
