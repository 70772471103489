import React, {useEffect} from "react";
import {Link, Navigate, useNavigate  } from "react-router-dom";

import {eraseCookie, setCookie} from "../../common/cookies";
import css from './SideMenu.module.css'
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {setActiveTab} from "../../redux/appReducer";


const SideMenu: React.FC = (props: any) => {

    const clickLink = (url:string) => {
        props.setActiveTab(url)
    }

    const { t, i18n} = useTranslation('',{ keyPrefix: 'sideMenu' });

    // const setLanguage:any = (lang: string) => {
    //     setCookie('lang',lang,7);
    //     i18n.changeLanguage(lang);
    //     // console.log('sl - ' + lang)
    //     let ru_lang = window.document.getElementById('lang_ru');
    //     let en_lang = window.document.getElementById('lang_en');
    //
    //     if(lang === 'ru') {
    //         if(ru_lang) ru_lang.style.color ='#090';
    //         if(en_lang) en_lang.style.color ='';
    //     }
    //     if(lang === 'en') {
    //         if(en_lang) en_lang.style.color ='#090';
    //         if(ru_lang) ru_lang.style.color ='';
    //     }
    // }




    return <nav>

        {!props.auth.authStatus ? null
            // <ul>
            //     <li>
            //         <Link to="/login">{t('Login')}</Link>
            //     </li>
            //     <li>
            //         <Link to="/signup">{t('SignUp')}</Link>
            //     </li>
            // </ul>
            :
            <>
                <ul>
                    <li>
                        <Link to="/" onClick={()=>clickLink('/')}
                              className={props.app.activeMenuTab === '/' ? css.active : undefined}>{t('Home')}</Link>
                    </li>

                    <li>
                        <Link to="/clients" onClick={()=>clickLink('/clients')}
                              className={props.app.activeMenuTab === '/clients' ? css.active : undefined}>{t('Clients')}</Link>
                    </li>
                    <li>
                        <Link to="/users" onClick={()=>clickLink('/users')}
                              className={props.app.activeMenuTab === '/users' ? css.active : undefined}>{t('Users')}</Link>
                    </li>
                    <li>
                        <Link to="/cc">{t('Clearing Companies')}</Link>
                    </li>
                    <li>
                        <Link to="/c2c">{t('Click-2-Call')}</Link>
                    </li>
                    <li>
                        <Link to="/transactions">{t('Transactions')}</Link>
                    </li>
                    <li>
                        <Link to="/kyc">{t('KYC')}</Link>
                    </li>
                    <li>
                        <Link to="/aff">{t('Affiliates')}</Link>
                    </li>

                </ul>

            </>


        }

    </nav>
}

export default SideMenu;