import {API} from "../api/API";

// const SET_AUTH_STATUS = 'crm/clients/SET_AUTH_STATUS';
const SET_NEW_CLIENT_ADDED_STATUS = 'crm/users/SET_NEW_CLIENT_ADDED_STATUS';
const SET_NEW_CLIENT_ADDED_ERROR = 'crm/users/SET_NEW_CLIENT_ADDED_ERROR';
const SET_NEW_CLIENT_ADDED_ID = 'crm/users/SET_NEW_CLIENT_ADDED_ID';
const SET_LOADING = 'crm/clients/SET_LOADING';
const SET_CLIENTS = 'crm/clients/SET_CLIENTS';

type Client = {
    clientId: number | null,
    fullName: string | null,
    email: string | null,
    phone: string | null,
    desk: string | null,
}
type SelectedClient = {
    clientId: number | null,
    fullName: string | null,
    email: string | null,
    role: string | null,
    desk: string | null,
    active: boolean | null
}

let initialState = {
    clients: []  as Array<Client>,
    selectedClient: {
        clientId: null,
        fullName: null,
        email: null,
        role: null,
        desk: null,
        active: null
    } as SelectedClient,
    pass: null as string | null,
    fullName: null as string | null,
    phone: null as string | null,
    type: 'lead' as 'lead' | 'client' | null,
    status: null as string | null,
    rememberMe: null as boolean | null,
    newClientAddedStatus: false,
    newClientAddedError: false,
    newClientAddedErrorText: '' as string,
    newClientAddedId: null as null | number,
    loading: false as boolean | null

}

const clientsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_NEW_CLIENT_ADDED_STATUS:
            return {
                ...state,
                newClientAddedStatus: action.status
            };
        case SET_NEW_CLIENT_ADDED_ERROR:
            return {
                ...state,
                newClientAddedError: action.error,
                newClientAddedErrorText: action.errorText
            };
        case SET_NEW_CLIENT_ADDED_ID:
            return {
                ...state,
                newClientAddedId: action.newClientAddedId
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_CLIENTS:
            return {
                ...state,
                clients: action.clients,
            };
        default:
            return state;
    }
}

const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading,
    }
)

const setClientsAC = (clients: any) => (
    {
        type: SET_CLIENTS,
        clients: clients,
    }
)

const setNewClientAddedStatusAC = (status: boolean) => (
    {
        type: SET_NEW_CLIENT_ADDED_STATUS,
        status: status
    }
)

const setNewClientAddedIdAC = (newClientAddedId: number) => (
    {
        type: SET_NEW_CLIENT_ADDED_ID,
        newClientAddedId: newClientAddedId
    }
)

const setNewClientAddedErrorAC = (error: boolean, errorText: string) => (
    {
        type: SET_NEW_CLIENT_ADDED_ERROR,
        error: error,
        errorText: errorText
    }
)

// thunks

export const getClients = (arrParams:any[] = [],limit:number = 10,offset:number = 0,orderBy:string = 'clientid',order:string = 'desc') => async (dispatch: any) => {

    await API.getClients(arrParams,limit,offset,orderBy,order)
        .then((data)=>{
            if(data.result === 'ok'){
                dispatch(setClientsAC(JSON.parse(data.clients)));
            }else{
                console.log(data);
            }
            dispatch(setLoading(false));

        })
        .catch((error)=>{
            console.error('error: ' + error)
            dispatch(setLoading(false));
        });
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setLoadingAC(loading))
}


export const setNewClientData = (email: string | undefined,password: string | undefined,fullName: string | undefined,isActive: boolean | undefined,isOnline: boolean | undefined,lastLogInDate: number | undefined,lastActionDate: number | undefined,lastAction: string | undefined,desk: string | undefined,type: string | undefined,brand: string | undefined,createDate: number | undefined,createdBy: string | undefined,assignedToID: number | undefined,phone: string | undefined,address: string | undefined,status: string | undefined,lastCommentDate: number | undefined,country: string | undefined,campaignID: number | undefined) => async (dispatch: any) => {
    // dispatch(setAuthDataAC(login, pass, rememberMe))
    await API.addClient(email,password,fullName,isActive,isOnline,lastLogInDate,lastActionDate,lastAction,desk,type,brand,createDate,createdBy,assignedToID,phone,address,status,lastCommentDate,country,campaignID)
        .then((data)=>{
            if(data.result === 'ok' && data.clientAdded === true){
                dispatch(setNewClientAddedStatusAC(true));
                dispatch(setNewClientAddedIdAC(data.userId));
                dispatch(setNewClientAddedErrorAC(false,''));
                dispatch(setLoadingAC(false));
            }else{
                dispatch(setNewClientAddedErrorAC(true,data.error));
                dispatch(setNewClientAddedStatusAC(false));
                dispatch(setLoadingAC(false));
                // debugger
            }
            console.log(data);
            // dispatch(setAuthStatusAC(data.status === 'logged in' ));
        })
        .catch((error)=>{
            console.error('error: ' + error)
        });

    // API - auth
    // if ok - set auth data null and  authStatus = true
    // if not ok - show error and  authStatus = true
}

export const setNewClientAddedStatus = (status: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setNewClientAddedStatusAC(status))
}


//

export default clientsReducer;