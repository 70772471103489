export const setCookie = (name: string | undefined,value: string | undefined,days: number | undefined) => {
    let expires = "";
    if (days){
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// type gc =

export const getCookie: any = (name: string | undefined) => {
    let surname = name + '=';
    let ca = document.cookie.split(";")
    for(let i = 0; i < ca.length; i++){
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if(c.indexOf(surname) === 0)
            return c.substring(surname.length,c.length);
    }
    return null;
}

export const eraseCookie: any = (name: string | undefined) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

