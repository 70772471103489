import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import AddUser from "./AddUser";
import {setLoading, setNewUserAddedStatus, setNewUserData} from "../../redux/usersReducer";

const AddUserContainer : React.FC = (props) => {

    return (
        <AddUser {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        users: state.users,
        app: state.app,
        desks: state.desks,
        brands: state.brands,
        statuses: state.statuses,
        campaigns: state.campaigns,
        countries: state.countries,
    }
};

export default compose(connect(mapStateToProps,{setNewUserData,setNewUserAddedStatus,setLoading}))(AddUserContainer);
