import {API} from "../api/API";
import store from "./store";

// let state = store.getState();

const SET_LANGUAGE = 'crm/app/SET_LANGUAGE';
const SET_ACTIVE_TAB = 'crm/app/SET_ACTIVE_TAB';
const SET_THEME = 'crm/app/SET_THEME';
// const SET_AUTH_DATA = 'crm/auth/SET_AUTH_DATA';

let initialState = {
    language: 'en' as string,
    activeMenuTab: '/' as string,
    theme: 'light' as 'light' | 'dark',
    // login: null as string | null,
    // pass: null as string | null,
    // rememberMe: null as boolean | null,
}

const appReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeMenuTab: action.activeTab
            };
        case SET_THEME:
            return {
                ...state,
                theme: action.theme
            };

        default:
            return state;
    }
}

// let onAuthStatusSet = (store.dispatch,state) => {
//
// }

// ActionCreator

const setLangAC = (language: string) => (
    {
        type: SET_LANGUAGE,
        language: language
    }
)
const setActiveTabAC = (activeTab: string) => (
    {
        type: SET_ACTIVE_TAB,
        activeTab: activeTab
    }
)
const setThemeAC = (theme: string) => (
    {
        type: SET_THEME,
        theme: theme
    }
)

// const setAuthDataAC = (login: string, pass: string, rememberMe: boolean) => (
//     {
//         type: SET_AUTH_DATA,
//         login: login,
//         pass: pass,
//         rememberMe: rememberMe,
//     }
// )

// ThunkCreator

export const setLanguage = (language: string) => (dispatch: any) => {
    dispatch(setLangAC(language))
}
export const setTheme = (theme: string) => (dispatch: any) => {
    dispatch(setThemeAC(theme))
}
export const setActiveTab = (activeTab: string) => (dispatch: any) => {
    dispatch(setActiveTabAC(activeTab))
}

// export const setAuthData = (login: string, pass: string, rememberMe: boolean) => async (dispatch: any) => {
//     dispatch(setAuthDataAC(login, pass, rememberMe))
//     await API.login(login, pass, rememberMe)
//         .then((data)=>{
//             console.log(data);
//             dispatch(setAuthStatusAC(data.status === 'logged in' ));
//         })
//         .catch((error)=>{
//             console.error('error: ' + error)
//         });
//
//     // API - auth
//     // if ok - set auth data null and  authStatus = true
//     // if not ok - show error and  authStatus = true
// }

export default appReducer;