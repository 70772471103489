import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Home from './components/Home/Home';
import Login from './components/LoginContainer/Login/Login';
import SignUp from "./components/SignUp/SignUp";
import Users from "./components/Users/Users";
import Clients from "./components/Clients/Clients";
import './App.css';
import {Col, ConfigProvider, Row, theme} from "antd";
import LoginContainer from "./components/LoginContainer/LoginContainer";
import {Provider} from 'react-redux';
import store from "./redux/store";
import SideMenuContainer from "./components/SideMenu/SideMenuContainer";
import UsersContainer from "./components/Users/UsersContainer";
import AddUserContainer from "./components/AddUser/AddUserContainer";
import {getCookie} from "./common/cookies";
import {useTranslation} from "react-i18next";
import {setAuthStatus} from "./redux/authReducer";
import IncludeContainer from "./components/Include/IncludeContainer";
import Theme from './components/Theme/Theme';
import ThemeContainer from "./components/Theme/ThemeContainer";

const App = (state: any) => {


    return (
        <Router>
            <Provider store={store}>
                <div>
                    <IncludeContainer/> {/* Exit & Language */}
                    <ThemeContainer/>   {/* Theme and Main */}
                </div>
            </Provider>
        </Router>
    );
};

export default App;
