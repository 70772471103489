import {API} from "../api/API";
import store from "./store";
import {eraseCookie, setCookie} from "../common/cookies";

// let state = store.getState();

const SET_AUTH_STATUS = 'crm/auth/SET_AUTH_STATUS';
const SET_AUTH_DATA = 'crm/auth/SET_AUTH_DATA';
const SET_USER_ID = 'crm/auth/SET_USER_ID';
const SET_LOADING = 'crm/auth/SET_LOADING';
const SET_ERROR = 'crm/auth/SET_ERROR';

let initialState = {
    authStatus: false as boolean | null,
    login: null as string | null,
    pass: null as string | null,
    rememberMe: null as boolean | null,
    loading: false as boolean | null,
    id: null as number | null,
    error: '' as string,
}

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_AUTH_STATUS:
            // debugger
            // state.authStatus = action.authStatus;
            return {
                ...state,
                authStatus: action.authStatus
            };
        case SET_AUTH_DATA:
            // debugger

            return {
                ...state,
                login: action.login,
                pass: action.pass,
                rememberMe: action.rememberMe
            };
        case SET_USER_ID:
            return {
                ...state,
                id: action.id,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}

// let onAuthStatusSet = (store.dispatch,state) => {
//
// }

// ActionCreator

const setAuthStatusAC = (authStatus: boolean | null) => (
    {
        type: SET_AUTH_STATUS,
        authStatus: authStatus
    }
)
const setErrorAC = (error: string | null) => (
    {
        type: SET_ERROR,
        error: error
    }
)

const setAuthDataAC = (login: string, pass: string, rememberMe: boolean) => (
    {
        type: SET_AUTH_DATA,
        login: login,
        pass: pass,
        rememberMe: rememberMe,
    }
)

const setUserIdAC = (id: number) => (
    {
        type: SET_USER_ID,
        id: id,
    }
)

const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading,
    }
)

// ThunkCreator

export const setAuthStatus = (status: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setAuthStatusAC(status))
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setLoadingAC(loading))
}

export const exit = () => async (dispatch: any,state:any) => {
    dispatch(setAuthStatusAC(false))
    eraseCookie('loggedIn');
    await API.exit(state.id);
}

export const setAuthData = (email: string, pass: string, rememberMe: boolean) => async (dispatch: any) => {
    dispatch(setAuthDataAC(email, pass, rememberMe))
    // debugger
    await API.login(email, pass, rememberMe)
        .then((data)=>{
            console.log(data);
            if(data.result === 'ok'){
                dispatch(setAuthStatusAC(true ));
                dispatch(setUserIdAC( data.userId ));
                dispatch(setLoadingAC( false ));
                setCookie('loggedIn','1',rememberMe ? 365 : 1/2);
                dispatch(setErrorAC(''));

            }else{
                dispatch(setLoadingAC( false ));
                dispatch(setAuthStatusAC(false ));
                dispatch(setErrorAC(data.error ));

            }

        })
        .catch((error)=>{
            console.error('error: ' + error)
        });

    // API - auth
    // if ok - set auth data null and  authStatus = true
    // if not ok - show error and  authStatus = true
}

export default authReducer;