// import React from 'react';
// import {Skeleton} from "antd";
//
// const Users = () => {
//     return (
//         <div>
//             {/*<h1>Users Page</h1>*/}
//             {/*<p>Users will be here soon...</p>*/}
//             <h1>Users Page</h1>
//             <p>(список пользователей CRM будет доступен только тимам и админам)</p>
//             <Skeleton />
//         </div>
//     );
// };

import React, { useEffect, useState } from 'react';
import type { GetProp, TableProps } from 'antd';
import {Input, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import css from "../SideMenu/SideMenu.module.css";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    fullName: string;
    // lastName: string;

    // gender: string;
    email: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    userid: number,
    createDate: number,
    desk: string,
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Users: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'users' });

    // const [data, setData] = useState<DataType[]>();
    // const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [dataSource, setDataSource] = useState(props.users.users);
    const [value, setValue] = useState('');

    useEffect(()=>{
        props.setLoading(true);
        props.getUsers(100);
    },[])

    useEffect(()=>{
        setDataSource(props.users.users);
    },[props.users.users])

    const FilterByNameInput = (
        <Input
            placeholder="Search Name"
            value={value}
            onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                const filteredData = props.users.users.filter((entry:any) =>
                    // fullName = entry.firstName + ' ' + entry.lastName;
                    entry.fullName.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
                // todo - change to filtering on server
            }}
        />
    );

    const columns: ColumnsType<DataType> = [
        {
            title: FilterByNameInput,
            dataIndex: 'fullName',
            // sorter: (a,b) => (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName),
            // render: (text, record) => (
            //     `${record.firstName} ${record.lastName}`
            // ),
            // width: '20%',
        },
        {
            title: 'Desk',
            dataIndex: 'desk',
            filters: [
                { text: 'Poland', value: 'poland' },
                { text: 'Spain', value: 'spain' },
            ],
            sorter: (a,b) => (a.desk).localeCompare(b.desk),
            width: '20%',
        },
        {
            title: 'Create date',
            dataIndex: 'createDate',
            filters: [
            ],
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.createDate - b.createDate,
            // sorter: true,
            width: '20%',
            render: (_,d) => new Date(d.createDate*1000).toUTCString(),
        },
        // {
        //     title: 'Age',
        //     dataIndex: 'dob',
        //     filters: [
        //     ],
        //     sorter: true,
        //     width: '20%',
        //     render: (a) => a.age,
        // },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a,b) => (a.email).localeCompare(b.email),
        },
    ];
    const fetchData = () => {
        // setLoading(true);
        // fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
        //     .then((res) => res.json())
        //     .then(({ results }) => {
        //         // setData(results);
        //         setLoading(false);
        //         setTableParams({
        //             ...tableParams,
        //             pagination: {
        //                 ...tableParams.pagination,
        //                 total: 200,
        //                 // 200 is mock data, you should read it from server
        //                 // total: data.totalCount,
        //             },
        //         });
        //     });
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    return (
        <>
            <h1>{t("Users List")}</h1>

                <Link to="/adduser" >{t('Add User')}</Link>
            <Table
                columns={columns}
                rowKey={(record) => record.userid}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.users.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}
            />
        </>

    );
};

export default Users;
