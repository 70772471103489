import React from 'react';
import css from './ShowError.module.css';

interface ShowErrorProps {
    errors: string[]; // Определяем, что errors — это массив строк
}

const ShowError: React.FC<ShowErrorProps> = ({ errors }) => {
    return errors.length < 1 ? null : (
        <div className={css.error}>
            {errors.map((error, i) => (
                <div key={i}>{error}</div>
            ))}
        </div>
    );
};

export default ShowError;
