import React from 'react';
import {setAuthData, setLoading} from "../../redux/authReducer";
import {compose} from "redux";
import { connect } from 'react-redux';
import Login from "./Login/Login";



const LoginContainer: React.FC = (props) => (

    <Login {...props} />
);



let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        app: state.app
    }
};

export default compose(connect(mapStateToProps,{setAuthData,setLoading}))(LoginContainer);
